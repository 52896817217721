const enCommon = {
    app_name: 'Endrex',
    app_title: 'Endrex — Copy Traiding Platform',
    page_title: '{{pageTitle}} - Endrex',
    socials: {
        facebook: 'Facebook',
        x: 'X',
        instagram: 'Instagram',
        discord: 'Discord',
        twitch: 'Twitch',
        youtube: 'YouTube',
        reddit: 'Reddit',
        telegram: 'Telegram',
        whatsapp: 'WhatsApp',
        linkedin: 'LinkedIn',
        tiktok: 'TikTok',
        web_page: 'Web Page',
    },
    paymentSystems: {
        maestro: 'Maestro',
        masterCard: 'Master Card',
        visa: 'Visa',
        payPal: 'PayPal',
        applePay: 'Apple Pay',
    },
    languages: {
        en: 'English',
        ru: 'Русский',
    },
    auth: {
        sign_in: {
            buttons: {
                email: 'E-mail',
                phone_number: 'Phone number',
            },
            steps: {
                initial: {
                    title: 'Sign In',
                    divider_text: 'Or',
                },
                credentials: {
                    email: {
                        title: 'Sign In with e-mail',
                        email_errors: {
                            min_length: 'E-mail field must not be empty',
                            incorrect_format: 'Please type email in format name@company.com',
                            invalid_email_or_password: 'Invalid e-mail or password',
                        },
                        password_errors: {
                            min_length: 'Password field must not be empty',
                            invalid_email_or_password: 'Invalid e-mail or password',
                            uncorrect_chars: 'Password could contain special characters(!@#$%^&*()_\\-+={}\\/:;"\'<>,.?~`), numbers and latin symbols',
                        },
                    },
                    phone_number: { title: 'Sign In with phone number' },
                    reset_password_text: '<0>I forgot password or can’t sign in</0>',
                },
                verification: {
                    email: {
                        title: 'Please check your e-mail',
                        text: 'We send confirmation link to your email {{email}}. If you don’t see please check spam',
                    },
                    phone: {
                        title: 'Verification Code',
                    },
                },
                backlink_text: 'back',
            },
            switch_text: 'Don’t have an account?',
            switch_link_text: 'Sign Up',
        },
        sign_up: {
            title: 'Sign Up',
            buttons: {
                email: 'E-mail',
                phone_number: 'Phone number',
            },
            steps: {
                initial: {
                    title: 'Sign Up',
                    divider_text: 'Or',
                },
                credentials: {
                    email: {
                        title: 'Sign Up',
                        email_input_title: 'E-mail',
                        email_input_placeholder: 'Enter e-mail',
                        password_input_title: 'Password',
                        password_input_placeholder: 'Enter password',
                        repeat_password_input_title: 'Repeat password',
                        repeat_password_input_placeholder: 'Enter password',
                        submit_btn_text: 'Submit',
                        email_errors: {
                            min_length: 'E-mail field must not be empty',
                            incorrect_format: 'Please type email in format name@company.com',
                            email_is_exist: 'This e-mail is already exists',
                        },
                        password_errors: {
                            min_length: 'Must be at least 8 characters long with letters and numbers',
                            similarity_error: 'The password is too similar to the email',
                            not_numeric: 'Your password can\'t be entirely numeric',
                            uncorrect_chars: 'Password could contain special characters(!@#$%^&*()_\\-+={}\\/:;"\'<>,.?~`), numbers and latin symbols',
                        },
                        repeat_password_errors: {
                            not_equal: 'Please type the exact password',
                        },
                    },
                    phone_number: { title: 'Sign In with phone number' },
                    terms_of_usage_text: 'I certify that I am 18 years of age or older, and I agree to <0>{{termsOfUsage}}</0> and <1>{{privacyPolicy}}</1>.',
                    terms_of_usage_link_text: 'Terms of use',
                    privacy_policy_link_text: 'Privacy policy',
                },
                verification: {
                    email: {
                        title: 'Please check your e-mail',
                        text: 'We send confirmation link to your email {{email}}. If you don’t see please check spam',
                        resend_code_link_text: 'Send link again',
                    },
                    phone: {
                        title: 'Verification Code',
                    },
                },
                backlink_text: 'back',
            },
            switch_text: 'Already have an account?',
            switch_link_text: 'Sign In',
        },
        reset_password: {
            steps: {
                find_account: {
                    title: 'Find your account',
                    input_label: 'Email, username or phone number including country code',
                    input_placeholder: 'Enter e-mail',
                    button_text: 'Submit',
                },
                reset_password_step: {
                    title: 'Reset password',
                    text: 'We found the following information associated your account',
                    radio_options: {
                        email: 'Email a link to {{email}}',
                        number: 'Send SMS to {{phone}}',
                    },
                    button_text: 'Submit',
                    unavailable_access_link: 'I don’t have access this email',
                },
                confirmation: {
                    title: 'Please check your e-mail',
                    text: 'We send reset link to your email {{email}}. If you don’t see please check spam',
                    resend_link_text: 'Send link again',
                    counter: 'Resend in {{counter}} sec',
                },
                backlink_text: 'back',
            },
        },
    },
    change_password: {
        title: 'Create new password',
        password_label: 'Password',
        password_placeholder: 'Enter password',
        repeat_password_label: 'Repeat password',
        repeat_password_placeholder: 'Enter password',
        submit_btn_text: 'Submit',
        change_password: 'Change Password',
        click_and_we_send_link: 'Click “Send Link” and we will send a link to your email',
        follow_the_link: 'Follow the link and create a new password',
        send_link: 'Send Link',
    },
    portfolio: {
        create_portfolio: {
            back_btn: 'back',
            safety_banner_text: 'Safety first - No one has access to your funds and all account information is encrypted',
            wallet_errors: {
                insufficient_amount: 'Balance must be more than $100',
            },
            steps: {
                select_exchange: {
                    title: 'Connect new exchange',
                    step_number: 'Step 1.',
                    step_text: 'Connect wallet',
                    unavailble_options_divider: 'Coming soon',
                    continue_btn: 'Continue',
                },
                connect_exchange: {
                    title: 'Connect {{exchangeName}} exchange',
                    step_number: 'Step 1.',
                    step_text: 'Connect wallet',
                    description: [
                        '1. Click on the "Connect" button',
                        '2. Log in to your account on the website Binance',
                        '3. Confirm your connection to Endrex',
                    ],
                    hint_text: 'Don’t have an account?',
                    hink_link: 'Create a new {{exchangeName}} Account',
                    continue_btn: 'Connect {{exchangeName}}',
                },
                select_wallet: {
                    title: 'Create Portfolio',
                    step_number: 'Step 1.',
                    step_text: 'Select wallet or connect new',
                    divider_text: 'Or',
                    connect_exchange_btn: 'Connect new exchange',
                    continue_btn: 'Continue',
                },
                portfolio_settings: {
                    title: 'Create Portfolio',
                    step_number: 'Step 2.',
                    step_text: 'Portfolio Settings',
                    parent_wallet: {
                        label: 'Wallet',
                    },
                    portfolio_name: { label: 'Portfolio Name' },
                    monthly_fee: { label: 'Monthly following fee' },
                    preview_label: 'Preview',
                    hint_text: 'These values are approximate and do not correspond to reality',
                    continue_btn: 'Create Portfolio',
                },
                success: {
                    title: 'Portfolio <0>{{portfolioName}}</0> created',
                    features: [
                        {
                            title: 'Mandatory 30-Day Assessment Review',
                            description: 'Your portfolio undergoes a preliminary review before it becomes listed on the marketplace',
                        },
                        {
                            title: 'Client Subscription Limit',
                            description: 'A maximum of 25 clients can subscribe to your portfolio',
                        },
                        {
                            title: 'Investment Cap per Client',
                            description: '1,000.00 USDT limit per client for portfolio copying',
                        },
                    ],
                    share_link: 'Share Link',
                    continue_btn: 'Close',
                    unverified_banner: {
                        title: 'Verify your account to remove restrictions and unlock full potential:',
                        description: [
                            'Full portfolio management with no restrictions',
                            'Instant marketplace visibility of your portfolio',
                            'Unlimited client subscriptions for maximum reach',
                        ],
                        verification_btn: 'Get Verification',
                    },
                },
                error: {
                    api_error: {
                        invalid_keys: 'Invalid API Key and Secret Key pair',
                    },
                    creation_error: {
                        title: 'Portfolio creation error',
                        text: 'We encountered an issue while attempting to set up your portfolio. Please try again. ',
                        assistance_text: 'If you need assistance, feel free to contact <0>Endrex support team</0>',
                        continue_btn: 'Close',
                    },
                    portfolio_limit: {
                        title: 'Portfolio Limit Reached',
                        description: 'Your current limit of 10 portfolios has been reached.',
                        hint: 'Please consider upgrading to the Enterprise Plan to increase your limit to 20 portfolios.',
                        get_enterprise_btn: 'Get Enterprise Plan',
                        continue_btn: 'Close',
                    },
                },
            },
        },
    },
    portfolio_overview: {
        user: '(You)',
        risks: {
            low: 'Low risk',
            average: 'Average risk',
            high: 'High risk',
        },
        sections: {
            button: {
                is_copying: 'Copying',
                start_copy: 'Copy Portfolio',
            },
            profit_review: {
                profit_period: 'Profit for {{period}}:',
                assets: 'Portfolio assets',
            },
            position: {
                title: 'Your position',
                edit_button_text: 'Edit',
                duration: 'Duration',
                unrealized_pl: 'Unrealized P/L',
                realized_pl: 'Realized P/L',
            },
            total_trades: {
                title: 'Total trades for {{period}}',
                weekly: 'Avg. Per week',
                avg_holding: 'Avg. Trade Duration',
                profit: 'Profitable trades',
                month_total: 'Past 30 days',
                min_trade_month: 'Min trade/month',
                max_trade_month: 'Max trade/month',
            },
            max_drawdown: {
                title: 'Max drawdown',
                daily: 'Past 24 h.',
                weekly: 'Past 7 d.',
                monthly: 'Past 30 d.',
                '90days': 'Past 90 d.',
            },
        },
    },
    profile: {
        real_summary_balance: 'Real Summary Balance',
        platform_balance: 'Platform Balance',
        connect_exchange: 'Connect exchange',
        edit_photo: {
            title: 'Edit Photo',
            btn_text: 'Save',
            file_upload: {
                inner_label: 'Drag and drop an image or browse',
                description: 'JPG, GIF or PNG. Max size of 5 MB',
                errors: {
                    big_file_size: 'Size to big. Please choose another photo',
                    incorrect_file_type: 'Invalid input type. Please provide only image files',
                },
                browse_btn: 'Browse photo',
            },
            delete_photo: 'Delete photo',
            save_btn: 'Save photo',
            cancel_btn: 'Cancel',
            continue_btn: 'Continue',
            delete_warning_text: 'Are you sure you want to delete a photo?',
            close_warning_text: 'Are you sure you want to close without saving?',
            accept_close_btn: 'Yes, I want to close',
            decline_close_btn: 'No, I want to continue',
            accept_delete_btn: 'Yes, I want to delete',
            decline_delete_btn: 'No, I want to keep',
        },
        create_trader: {
            steps: {
                initial: {
                    title: 'Become a Trader',
                    list: [
                        'Separate trader profile with different functionality',
                        'The user profile will also be available and you will be able to switch between them',
                        'The same exchangers cannot be used for the trader and user profiles',
                    ],
                    btn_text: 'Start',
                },
                introduction: {
                    title: 'Introduce Yourself',
                    description: 'Be sure to fill out these fields so that your clients trust you',
                    publicname: {
                        title: 'Public Name',
                    },
                    location: {
                        title: 'Location',
                        placeholder: 'Select a country',
                    },
                    nickname: {
                        title: 'Nickname',
                        placeholder: 'Robert',
                    },
                    btn_text: 'Continue',
                },
                add_photo: {
                    title: 'Add Photo',
                    file_input: {
                        title: 'Drag and drop an image or browse',
                        description: 'JPG, GIF or PNG. Max size of 5 MB',
                    },
                    btn_text: 'Skip',
                },
                edit_photo: {
                    title: 'Add Photo',
                    file_input: {
                        title: 'Drag and drop an image or browse',
                        description: 'JPG, GIF or PNG. Max size of 5 MB',
                    },
                    choose_photo: 'Choose another photo',
                    btn_text: 'Continue',
                },
                add_photo_success: {
                    text: 'Photo added',
                    btn_text: 'Continue',
                },
                get_verification: {
                    title: 'Get Verification',
                    list: [
                        'The user profile will also be available and you will be able to switch between them ',
                        'Separate trader profile with different functionality',
                        'The same exchangers cannot be used for the trader and user profiles',
                    ],
                    list_title: 'Pass verification and get more opportunities when creating a portfolio:',
                    continue_btn_text: 'Continue',
                    skip_btn_text: 'Skip',
                },
                personal_details: {
                    title: 'Personal Details',
                    description: 'Please enter the name exactly as it appears on your passport',
                    first_name: {
                        title: 'Name',
                    },
                    last_name: {
                        title: 'Last Name',
                    },
                    continue_btn_text: 'Continue',
                },
                address_details: {
                    title: 'Address Details',
                    address_line: {
                        title: 'Line',
                        placeholder: 'Flat no / House no',
                    },
                    additional_address_line: {
                        title: 'Line 2',
                        placeholder: 'Street name / Building no / Plot no',
                    },
                    city: {
                        title: 'City',
                        placeholder: 'Enter city name',
                    },
                    region: {
                        title: 'Region',
                        placeholder: 'Select Region',
                        default_value: 'Select Region',
                    },
                    country: {
                        title: 'Country',
                        placeholder: 'Enter country name',
                    },
                    postal_code: {
                        title: 'Postal / Zip code',
                        placeholder: 'Enter postal / zip code',
                    },
                    continue_btn_text: 'Continue',
                },
                identity_details: {
                    title: 'Indentity Details',
                    country_identity_name: {
                        title: 'Country Identity Name',
                        placeholder: 'Enter identity name',
                    },
                    tax_identity_number: {
                        title: 'Tax identity number',
                        placeholder: 'Enter identity number',
                    },
                    document_type: {
                        title: 'Document type',
                        placeholder: 'Select document type',
                        default_value: 'ID Card',
                    },
                    document_number: {
                        title: 'Document number',
                        placeholder: 'Enter document number',
                    },
                    continue_btn_text: 'Continue',
                },
                documents_upload: {
                    title: 'Upload Documents',
                    passport_photo: {
                        title: 'Upload passport size photo',
                        inner_label: 'Drag and drop an image or browse',
                        description: 'JPG, GIF or PNG. Max size of 800K',
                    },
                    face_photo: {
                        title: 'Upload selfie with document',
                        inner_label: 'Drag and drop an image or browse',
                        description: 'JPG, GIF or PNG. Max size of 800K',
                    },
                    continue_btn_text: 'Continue',
                },
                final: {
                    title: 'Your trader profile created',
                    verification_description: 'Verification may take up to 24 hours',
                    btn_text: 'Continue',
                },
                warning: {
                    text: 'You have unsaved changes. Are you sure you want to finish?',
                    approve_btn_text: 'Yes, I want to finish',
                    decline_btn_text: 'No, I want to continue',
                },
                backlink_btn: {
                    text: 'back',
                },
            },
        },
        get_verification: {
            text: 'Become a verified trader and gain more trust from your clients',
            btn_text: 'Get verification',
        },
    },
    locations: {
        RUSSIA: 'Russia',
        USA: 'USA',
        UK: 'England',
        germany: 'Germany',
        georgia: 'Georgia',
    },
    actions: {
        topup: 'Add',
        edit: 'Edit',
        save: 'Save',
        save_changes: 'Save Changes',
        cancel: 'Cancel',
        enter_link: 'Enter Link',
        copy_link: 'Copy Link',
        log_in: 'Log in',
        log_out: 'Log out',
        update: 'Update',
        continue: 'Continue',
        follow: 'Follow',
        unfollow: 'Unfollow',
        remove: 'Remove',
        show_more: 'Show More',
        show_less: 'Show Less',
        create_trader: 'Become a trader',
        complete_profile: 'Complete Profile',
        report_user: 'Report User',
        connect: 'Connect',
        manage: 'Manage',
        delete: 'Delete',
    },
    statuses: {
        loading: 'Loading...',
        copied: 'Copied!',
    },
    errors: {
        not_found: 'Not Found',
        internal_server_error: 'Internal Server Error',
        copy_to_clipboard: {
            unsupported: 'Unfortunately the copy-to-clipboard feature is not supported by your browser.',
            error: 'An error occured while trying to copy-to-clipboard, please contact the support.',
        },
    },
    placeholders: {
        search: 'Search',
        unauthorized: 'You are unauthorized. Need to login',
        not_implemented: 'This feature will be coming soon',
        unsupported: 'Unsupported',
        login: 'Login',
        other: 'Other',
    },
    settings: {
        switch_to_client: 'Switch to Client',
        switch_to_trader: 'Switch to Trader',
        create_trader_profile: 'Create Trader Profile',
        theme: 'Theme',
        language: 'Language',
        themes: {
            light: 'Light',
            dark: 'Dark',
        },
        backlink_text: 'User information',
    },
    pages: {
        home: 'Home',
        overview: {
            overview: 'Overview',
            exchanges: 'Exchanges',
            balance: 'Balance',
        },
        marketplace: {
            marketplace: 'Marketplace',
            active: 'Active',
            history: 'History',
            saved: 'Saved',
        },
        portfolios: {
            general_portfolio_information: {
                title: 'General Portfolio Information',
                tabs: {
                    profit: 'Profit',
                    manager_assets: 'Manager Assets',
                    followers_assets: 'Followers Assets',
                    margin: 'Margin',
                    copiers_number: 'Copiers Number',
                    risks: 'Risks',
                },
                duration_select: {
                    all: 'All',
                    '7_days': '7D',
                    '30_days': '30D',
                    '90_days': '90D',
                    '180_days': '180D',
                },
                trading_activity: {
                    title: 'Trading Activity',
                    scale_less: 'Less',
                    scale_more: 'More',
                    total_trades: 'Total trades for 365 days',
                    active_days: 'Total active days',
                    max_trades_monthy: 'Max. trade/month',
                    day_activity: '{{activityCounter}} on {{activityDate}}',
                },
            },
            current_positions: 'Current Positions',
            history: 'History',
            copiers: 'Copiers',
        },
        terminal: 'Terminal',
        bot_lab: 'Bot Lab',
        messages: 'Messages',
        settings: 'Settings',
        support: 'Support',
        profile: 'Profile',
        my_profile: 'My Profile',
        shortcuts: 'Shortcuts',
        not_found: 'Not Found',
        confirm_email: 'Email confirmation',
        confirm_password: 'Password confirmation',
        coming_soon: 'Coming soon',
    },
    navigation: {
        go_back: 'Go Back',
        back_to: 'Back to',
        next: 'Next',
        previous: 'Previous',
        go_to: 'Go to {{location}}',
        search: {
            placeholder: 'Search',
            sections: {
                portfolios: 'Portfolios ({{count}})',
                traders: 'Traders ({{count}})',
                clients: 'Clients ({{count}})',
            },
        },
    },
    links: {
        terms_and_conditions: 'Terms & Conditions',
        privacy_policy: 'Privacy Policy',
        copyrights: '© 2024 Endrex',
    },
    periods: {
        hour: 'Hour',
        hour_one: 'Hour',
        hour_few: 'Hours',
        hour_many: 'Hours',
        hour_other: 'Hours',
        month: 'Month',
        month_one: 'Month',
        month_few: 'Month',
        month_many: 'Months',
        month_other: 'Months',
        year: 'Year',
        year_one: 'Year',
        year_few: 'Years',
        year_many: 'Years',
        year_other: 'Years',
        day: 'Day',
        day_one: 'Day',
        day_few: 'Days',
        day_many: 'Days',
        day_other: 'Days',
    },
    plural_variables: {
        follower: 'Follower',
        follower_one: 'Follower',
        follower_few: 'Followers',
        follower_many: 'Followers',
        follower_other: 'Followers',
        trade: 'Trade',
        trade_one: 'Trade',
        trade_few: 'Trades',
        trade_many: 'Trades',
        trade_other: 'Trades',
        trading_pair: 'Trading pair',
        trading_pair_one: 'Trading pair',
        trading_pair_few: 'Trading pairs',
        trading_pair_many: 'Trading pairs',
        trading_pair_other: 'Trading pairs',
        closed_position: 'Closed Position',
        closed_position_one: 'Closed Position',
        closed_position_few: 'Closed Positions',
        closed_position_many: 'Closed Positions',
        closed_position_other: 'Closed Positions',
        available_portfolios: 'Available',
        available_portfolios_one: 'Available',
        available_portfolios_few: 'Available',
        available_portfolios_many: 'Available',
        available_portfolios_other: 'Available',
    },
    date_time: {
        duration: {
            days: '{{count}} d.',
            hours: '{{count}} h.',
            minutes: '{{count}} m.',
            seconds: '{{count}} s.',
        },
        periods: {
            short: {
                day_one: '{{count}} D',
                day_few: '{{count}} D',
                day_many: '{{count}} D',
                day_other: '{{count}} D',
                week_one: '{{count}} W',
                week_few: '{{count}} W',
                week_many: '{{count}} W',
                week_other: '{{count}} W',
                month_one: '{{count}} M',
                month_few: '{{count}} M',
                month_many: '{{count}} M',
                month_other: '{{count}} M',
                year_one: '{{count}} Y',
                year_few: '{{count}} Y',
                year_many: '{{count}} Y',
                year_other: '{{count}} Y',
            },
            long: {
                day_one: '{{count}} Day',
                day_few: '{{count}} Days',
                day_many: '{{count}} Days',
                day_other: '{{count}} Days',
                week_one: '{{count}} Week',
                week_few: '{{count}} Weeks',
                week_many: '{{count}} Weeks',
                week_other: '{{count}} Weeks',
                month_one: '{{count}} Month',
                month_few: '{{count}} Months',
                month_many: '{{count}} Months',
                month_other: '{{count}} Months',
                year_one: '{{count}} Year',
                year_few: '{{count}} Years',
                year_many: '{{count}} Years',
                year_other: '{{count}} Years',
            },
            all: 'All',
            all_time: 'All Time',
        },
        month: {
            short: {
                january: 'Jan',
                february: 'Feb',
                march: 'Mar',
                april: 'Apr',
                may: 'May',
                june: 'Jun',
                july: 'Jul',
                august: 'Aug',
                september: 'Sep',
                october: 'Oct',
                november: 'Nov',
                december: 'Dec',
            },
        },
    },
    wallet_type: {
        SPOT: 'SPOT',
        MARGIN: 'MARGIN',
    },
    asset_type: {
        USDT: 'USDT',
    },
};
export default enCommon;
